.mar-auto{
    margin: 50px 20px !important;
}
.paginationSpacing{
    margin:20px;
}
.user-search{
    display: flex;
    align-items: right;
    justify-content: end; margin: 10px;
}
.textRed {
    font-size: 12px;
    color: red;
}

.tab-content {
    box-shadow: none !important;
}

.table-transform {
    height: 0;
    width: max-content;
    transform-origin: top left;
    transform: rotate(-90deg);
}

.print-hide {
    display: none;
}

.hidden {
    display: none;
}

.visible {
    display: block;
}

.balance-sheet-print {
    zoom: 86%;
}
.cashFlow-print {
    zoom: 90%;
}
.income-statement-print {
    zoom: 88%;
}