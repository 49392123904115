.form-label,
.col-form-label {
    text-transform: none !important;
}

.autoResize{
    display: block;
    overflow: hidden !important;
    resize: none;
}

.table th {
    text-transform: none !important;
}